import Vue from 'vue'
import Router from 'vue-router'
Vue.use(Router)

/**
 * 固定路由请根据实际情况自行修改
 */
export const constantRoutes = [
  {
    path: '/',
    component: () => import('@/components/login'),
  },
  {
    path: '/home',
    component: () => import('@/components/home'),
  },
  {
    path: '/authenticity',
    component: () => import('@/components/authenticity'),
  },
  {
    path: '/verifyCheck',
    component: () => import('@/components/verifyCheck'),
  }
  // ,
  // {
  //   path: '/demo',
  //   component: () => import('@/components/demo'),
  // }
]

/**
 *
 */
// 解决菜单跳转相同路径下报错
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
}
export const asyncRoutes = []
const createRouter = () =>
  new Router({
    // mode: 'history',
    // base: '/dist-production',
    scrollBehavior: () => ({ y: 0 }),
    routes: constantRoutes
  })
const router = createRouter()
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}
export default router
