import Vue from 'vue'
import App from './App.vue'
import router from './router'
// 引入jQuery
import '../node_modules/bootstrap/dist/css/bootstrap.css'
import '../node_modules/bootstrap/dist/js/bootstrap.js'
Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App),

}).$mount('#app')
