<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: 'App',
}
</script>

<style>
body {
  padding: 0;
}
html,
body,
#app {
  height: 100%;
  margin: 0;
  padding: 0;
}
#app {
  margin: 0 auto;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
    'Helvetica Neue', Arial, sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji',
    'Segoe UI Symbol', 'Noto Color Emoji';
  color: #212529;
  height: 100%;
}
*,
::after,
::before {
  box-sizing: border-box;
}
</style>
